<template>
  <footer id="footer" class="bg-light foot-content">
    <div class="pt-5 mt-5">
      <div class="container">
        <div class="row">
          <!-- Contact Details -->
          <div class="col-sm-6 col-md-3 mb-4">
            <div class="widget-title text-center mb-4">
              <h3 class="text-secondary text-uppercase">Call Us Today</h3>
            </div>
            <h4 class="text-center mobile">+254 726 250 134</h4>
            <p class="text-secondary m-0 mt-3 text-center">
              Mon. - Fri. <b>8:00 AM - 7:00 PM</b>
            </p>
            <p class="text-secondary text-center">Sat. - Sun. <b>Flexible Hours</b></p>
          </div>

          <!-- Request Quote Form -->
          <div class="col-sm-12 col-md-6 mb-4">
            <div class="success centering-items" v-if="sentEmail">
              <span class="email-alert">EMAIL SENT</span>
            </div>
            <form @submit.prevent="sendEmail" v-if="notSent">
              <div class="card shadow-sm rounded-3">
                <div class="card-header bg-dark text-white text-center py-3">
                  <h3>Request a Quote</h3>
                </div>
                <div class="card-body">
                  <!-- Form Fields -->
                  <div class="form-group mb-3">
                    <input
                      type="text"
                      class="form-control rounded-pill"
                      id="names"
                      name="name"
                      placeholder="Enter Full Names"
                      required
                      v-model="name"
                    />
                  </div>
                  <div class="form-group mb-3">
                    <input
                      type="email"
                      class="form-control rounded-pill"
                      id="email"
                      name="email"
                      placeholder="Enter Email Address"
                      required
                      v-model="email"
                    />
                  </div>
                  <div class="form-group mb-3">
                    <input
                      type="text"
                      class="form-control rounded-pill"
                      id="number"
                      name="number"
                      placeholder="Enter Phone Number"
                      required
                      v-model="phone"
                    />
                  </div>
                  <div class="form-group mb-3">
                    <textarea
                      class="form-control rounded-3"
                      placeholder="Briefly describe what you need"
                      required
                      name="message"
                      v-model="message"
                    ></textarea>
                  </div>
                  <button class="btn btn-dark w-100 mb-3 rounded-pill">Send Email</button>
                </div>
              </div>
            </form>
          </div>

          <!-- Google Map -->
          <div class="col-sm-6 col-md-3">
            <iframe
              class="w-100 rounded-3"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d255348.15986362836!2d34.59807888807205!3d-0.07394211911515454!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x182aa496ab60aebd%3A0x71c2d31867dd2a11!2sKisumu%20City%20Business%20Park!5e0!3m2!1sen!2ske!4v1669022480458!5m2!1sen!2ske"
              style="border: 0"
              allowfullscreen=""
              loading="lazy"
              height="250px"
              referrerpolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  </footer>

  <div class="container-fluid bottom-content">
    <p class="text-center text-white mt-3">
      <span class="copyright">© {{ currentYear }} Copyright: Viviana's Catering Services</span>
    </p>
  </div>
</template>

<script>
import emailjs from "emailjs-com";
export default {
  name: "contact",
  data() {
    return {
      name: "",
      email: "",
      message: "",
      phone: "",
      sentEmail: false,
      notSent: true,
      currentYear: new Date().getFullYear(),
    };
  },
  methods: {
    sendEmail(e) {
      emailjs.sendForm(
        "service_p9hjj89",
        "template_kxgbtnn",
        e.target,
        "Sj662AO5ZVrD37wJ4",
        {
          name: this.name,
          email: this.email,
          message: this.message,
          number: this.phone,
        }
      ),
        // Reset form field
        (this.name = ""),
        (this.email = ""),
        (this.message = ""),
        (this.phone = ""),
        (this.sentEmail = true),
        (this.notSent = false);
    },
  },
};
</script>

<style scoped>
.centering-items {
  display: flex;
  justify-content: center;
  align-items: center;
}

.quote-header {
  background-color: rgb(40, 40, 43);
}

.bottom-content {
  background-color: rgb(40, 40, 43);
  height: 9vh;
}

.btn {
  font-family: "Montserrat", sans-serif;
}

.btn:hover {
  transform: scale(1.01);
  color: #f9d423;
}

.copyright {
  color: #f9d423;
}

.email-alert {
  color: green;
  font-weight: bold;
  font-size: 50px;
  font-family: "Montserrat", sans-serif;
}
</style>
