<template>
  <section class="hero-section" id="hero">
    <div class="text-center hero-pic">
      <div class="mask">
        <div class="d-flex justify-content-center align-items-center h-100">
          <div
            class=""
            v-motion
            :initial="{
              opacity: 0,
              y: 200,
            }"
            :enter="{
              opacity: 1,
              y: 0,
              transition: {
                duration: 2000,
              },
            }"
          >
            <div class="vivlogo">
              <img
                src="../assets/vivlogo2.webp"
                alt="vivlogo"
                width="300"
                height="200"
                class="img-fluid"
              />
            </div>
            <div class="text-center">
              <div>
                <h3 class="text-white mt-3">
                  Events Planning & Catering Services
                </h3>
              </div>
              <a
                class="btn btn-outline-light btn-md mt-3 homebutton"
                href="#footer"
                role="button"
              >
                Request a Quote
              </a>

              <hr class="custom-hr" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="about-section" id="about">
    <div class="about-container">
      <div>
        <Carousel :items-to-show="1" :wrap-around="true" :autoplay="5000">
          <Slide v-for="aboutSlide in aboutSlides" :key="aboutSlide.id">
            <div class="carousel__item2">
              <div class="caro mt-5">
                <h3>{{ aboutSlide.header }}</h3>

                <div class="container">
                  <p>{{ aboutSlide.content }}</p>
                </div>

                <div class="sliding-icons">
                  <img
                    :src="aboutSlide.url"
                    alt=""
                    class="about-photos img-fluid"
                  />
                </div>
              </div>
            </div>
          </Slide>

          <template #addons>
            <Navigation />
            <Pagination />
          </template>
        </Carousel>

        <div class="container mt-5">
  <div class="row g-3">
    <!-- Bootstrap grid with spacing -->
    <div class="col-6 col-md-4 col-lg-3">
      <img
        src="../assets/cooking-min.webp"
        alt="Chef"
        class="img-fluid rounded-3"
        style="max-height: 200px; object-fit: cover;"
      />
    </div>
    <div class="col-6 col-md-4 col-lg-3">
      <img
        src="../assets/decor-min.webp"
        alt="Decor"
        class="img-fluid rounded-3"
        style="max-height: 200px; object-fit: cover;"
      />
    </div>
    <div class="col-6 col-md-4 col-lg-3">
      <img
        src="../assets/dj-min.webp"
        alt="DJ"
        class="img-fluid rounded-3"
        style="max-height: 200px; object-fit: cover;"
      />
    </div>
    <div class="col-6 col-md-4 col-lg-3">
      <img
        src="../assets/buffetserve.webp"
        alt="Caterers"
        class="img-fluid rounded-3"
        style="max-height: 200px; object-fit: cover;"
      />
    </div>
    <div class="col-6 col-md-4 col-lg-3">
      <img
        src="../assets/buffettwo.webp"
        alt="Caterers"
        class="img-fluid rounded-3"
        style="max-height: 200px; object-fit: cover;"
      />
    </div>
    <div class="col-6 col-md-4 col-lg-3">
      <img
        src="../assets/cooks.webp"
        alt="Caterers"
        class="img-fluid rounded-3"
        style="max-height: 200px; object-fit: cover;"
      />
    </div>
    <div class="col-6 col-md-4 col-lg-3">
      <img
        src="../assets/venue.webp"
        alt="Caterers"
        class="img-fluid rounded-3"
        style="max-height: 200px; object-fit: cover;"
      />
    </div>
    <div class="col-6 col-md-4 col-lg-3">
      <img
        src="../assets/setup.webp"
        alt="Caterers"
        class="img-fluid rounded-3"
        style="max-height: 200px; object-fit: cover;"
      />
    </div>
    <div class="col-6 col-md-4 col-lg-3">
      <img
        src="../assets/tents.webp"
        alt="Caterers"
        class="img-fluid rounded-3"
        style="max-height: 200px; object-fit: cover;"
      />
    </div>
  </div>
</div>
>

        <div class="client-logos mt-5">
          <div class="text-center">
            <h4>Our Clients</h4>
          </div>
          <Carousel
            :items-to-show="5"
            :wrap-around="true"
            :autoplay="3000"
            class="d-flex justify-content-center align-items-center"
          >
            <Slide v-for="slide in slides" :key="slide.id">
              <div class="carousel__item text-center">
                <img :src="slide.url" alt="" class="img-fluid p-2" />
              </div>
            </Slide>
          </Carousel>
        </div>
      </div>
    </div>
  </section>

<section class="team-section container p-4 mt-5" id="team">
  <div class="text-center mb-4">
    <h2 class="text-white">The Team</h2>
  </div>

  <div class="row row-cols-1 row-cols-sm-2 row-cols-md-3 g-4">
    <div class="col">
      <div class="actor-card">
        <div class="actor-image">
          <img src="../assets/vivian.webp" alt="Vivian" class="img-fluid" />
          <div class="actor-overlay">
            <h5>VIVIAN SARAH - CEO</h5>
          </div>
        </div>
        <div class="actor-info">
          <h5>VIVIAN SARAH - CEO</h5>
          <p>
            A passionate and successful businesswoman with an eye for offering quality services backed by years of experience.
          </p>
        </div>
      </div>
    </div>

    <div class="col">
      <div class="actor-card">
        <div class="actor-image">
          <img src="../assets/el.webp" alt="Elvince" class="img-fluid" />
          <div class="actor-overlay">
            <h5>ELVINCE - CHEF</h5>
          </div>
        </div>
        <div class="actor-info">
          <h5>ELVINCE - CHEF</h5>
          <p>
            An experienced chef with an educational background in food science. He is a culinary master.
          </p>
        </div>
      </div>
    </div>

    <div class="col">
      <div class="actor-card">
        <div class="actor-image">
          <img src="../assets/elvince.webp" alt="Smith" class="img-fluid" />
          <div class="actor-overlay">
            <h5>SMITH - CATERING STEWARD</h5>
          </div>
        </div>
        <div class="actor-info">
          <h5>SMITH - CATERING STEWARD</h5>
          <p>
            A team player that works around the clock to make sure client's instructions are followed to make the event a success.
          </p>
        </div>
      </div>
    </div>
  </div>
</section>

</template>

<script>
import "vue3-carousel/dist/carousel.css";
import { Carousel, Slide, Navigation, Pagination } from "vue3-carousel";

export default {
  name: "Maincontent",
  components: { Carousel, Pagination, Slide, Navigation },

  data() {
    return {
      slides: [
        { id: "1", url: require("@/assets/vivlogo.webp") },
        { id: "2", url: require("@/assets/blueridgelogo.webp") },
        { id: "3", url: require("@/assets/kpa.webp") },
        { id: "4", url: require("@/assets/kuzalogo.webp") },
        { id: "5", url: require("@/assets/unga.webp") },
        { id: "6", url: require("@/assets/tupande.webp") },
        { id: "7", url: require("@/assets/bloom.webp") },
        { id: "8", url: require("@/assets/volcha.webp") },
      ],
      aboutSlides: [
        {
          id: "2",
          header: "Who we are",
          url: require("@/assets/team.webp"),
          content:
            "We are a team of caterers and event planners with years of experience.",
        },
        {
          id: "3",
          header: "Clientele",
          url: require("@/assets/wed.webp"),
          content:
            "Trusted by hundreds of clients to handle events ranging from weddings, birthdays,corporate forums, school events, funerals & many more.",
        },
        {
          id: "3",
          header: "History",
          url: require("@/assets/timeline.webp"),
          content:
            "Vivianas Catering was founded in 2017 by Vivian Sarah with an aim to provide affordable catering services in Kisumu. The company has since then grown & worked with clients all over the country",
        },
        {
          id: "3",
          header: "Why us",
          url: require("@/assets/catering.webp"),
          content:
            "We provide the most affordble catering rates. We are willing to go the extra mile to make your event a success.",
        },
      ],

      gra: null,
      init: null,
    };
  },
};
</script>

<style scoped>
.custom-color {
  color: rgb(219, 201, 42) !important;
}

.mask {
  height: 100vh;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.6);
}

.mgin {
  margin: 10px;
}

.hero-pic {
  background-image: url("../assets/setup.webp");
  background-size: cover;
}

.about-section {
  background-color: white;
  min-height: 100vh;
}

.carousel__item {
  width: 100%;
  justify-content: center;
  align-items: center;
}

.imagery {
  height: auto;
  width: 70px;
}

.carousel__item2 {
  width: 100%;
  font-size: 20px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.client-logos {
  margin-top: 2%;
}

.about-photos {
  max-width: 250px;
  height: 250px;
}

.centering-items {
  display: flex;
  justify-content: center;
  align-items: center;
}

.actor-card {
  background-color: white;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
  margin: 10px;
  flex: 0 0 260px;
  display: flex;
  flex-direction: column;
  height: 400px; /* Ensure the card size remains fixed */
}

.actor-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.15);
}

.actor-image {
  position: relative;
  overflow: hidden;
  height: 280px; 
}

.actor-image img {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Keeps the aspect ratio while covering the container */
  object-position: top; 
  transition: transform 0.5s ease;
}

.actor-card:hover .actor-image img {
  transform: scale(1.1);
}

.actor-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.actor-card:hover .actor-overlay {
  opacity: 1;
}

.actor-overlay h5 {
  color: #f9d423;
  font-weight: 700;
  margin-bottom: 5px;
}

.actor-overlay p {
  color: #f9d423;
  font-size: 0.9rem;
}

.actor-info {
  flex-grow: 1; /* Ensures the card body doesn't stretch beyond its fixed height */
  padding: 10px 15px;
  text-align: center;
  overflow: hidden; /* Prevents content overflow */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.actor-info p {
  font-size: 1rem;
  color: #333;
  flex-grow: 1; /* Ensures text does not affect the layout */
 
}




.homebutton {
  font-family: "Montserrat", sans-serif;
}

.form-wrapper {
  width: 50%;
}

.borders {
  border: 2px solid green;
}

.btn.btn-outline-light {
  color: rgb(219, 201, 42);
  border-color: rgb(219, 201, 42);
}

.btn.btn-outline-light:hover {
  background-color: rgb(219, 201, 42);
  color: white;
}

@media (max-width: 768px) {
  .hero-pic {
    background-image: url("../assets/venue.webp");
    background-size: center center;
    background-repeat: no-repeat;
  }
}
</style>
