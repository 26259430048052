<template>
  <div class="whatsapp-float">
    <a href="#" @click.prevent="openWhatsApp()">
      <font-awesome-icon class="whatsapp-icon rounded-circle" icon="fa-brands fa-whatsapp" />
       <span class="whatsapp-label">Chat</span>
    </a>
  </div>
</template>
<script>
export default {
  methods: {
    openWhatsApp() {
     
      const url = `https://api.whatsapp.com/send?phone=254726250134&text=Hello%20I%20need%20a%20catering%20service`;
      window.open(url, '_blank');
    },
  },
};
</script>


<style scoped>

a{
     text-decoration: none;


}

.whatsapp-float {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 100;
}

.whatsapp-icon {
  font-size: 40px;
  color: #25d366;
  
 
}

.whatsapp-label {
  display: block;
  font-size: 15px;
  color: #25d366;
  text-align: center;
  margin-top: 5px;
  font-weight: bold;
 
}

</style>